<script>
  import { createEventDispatcher } from 'svelte'

  //slider variables
  export let frames = []
  export let selected
  export let mainColor = '#00bc8e'
  let slider
  let isDown = false
  let startX
  let scrollLeft
  // let selected = frames[0]

  const dispatch = createEventDispatcher()

  function chooseOption(frame) {
    // selected = frame
    dispatch('choose', {
      frame,
    })
  }

  const mousedown = e => {
    isDown = true
    slider.classList.add('active')
    startX = e.pageX - slider.offsetLeft
    scrollLeft = slider.scrollLeft
  }
  const mouseleave = () => {
    isDown = false
    slider.classList.remove('active')
  }
  const mouseup = () => {
    isDown = false
    slider.classList.remove('active')
  }

  const mousemove = e => {
    if (!isDown) return
    e.preventDefault()
    const x = e.pageX - slider.offsetLeft
    const walk = (x - startX) * 2 //scroll-fast
    slider.scrollLeft = scrollLeft - walk
  }
</script>

<style>
  /* width */
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .badgeSelector {
    background-color: #ccc;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    cursor: grab;
  }
  .badge {
    display: inline-block;
    margin: 0.5rem;
    transition: 0.2s;
  }
  .badgeImage {
    max-width: 120px;
    border: 5px solid rgba(255, 255, 255, 0);
  }
  .selected {
    border: 5px solid var(--theme-color);
  }
</style>

<div
  class="badgeSelector"
  bind:this={slider}
  on:mousedown={mousedown}
  on:mouseleave={mouseleave}
  on:mouseup={mouseup}
  on:mousemove={mousemove}>

  {#each frames as frame, i}
    <div class="badge">
      <img
        style="--theme-color: {mainColor}"
        class="badgeImage"
        class:selected={frame === selected}
        src={frame}
        alt=""
        on:click={() => chooseOption(frame)} />
    </div>
  {/each}
</div>
