<script>
  import { onMount } from 'svelte'
  import getCoverSize from '@agilie/canvas-image-cover-position'
  import { shareResult, downloadResult } from './utils/userActions.js'
  import {
    Share2Icon,
    DownloadIcon,
    CameraIcon,
    UploadIcon,
  } from 'svelte-feather-icons'
  import Slider from './Slider.svelte'
  import Loading from './Loading.svelte'

  export let client

  let isLoading = true
  let error = false
  let frames = []
  let frame = frames[0]

  //contentful variables
  let message = ''
  let mainColor = '#00bc8e'
  let fileName = 'mi-photobooth'
  let title = ''

  let constraints = { video: { facingMode: 'user' }, audio: false }
  let cameraView
  let cameraOutput
  let cameraSensor
  let src
  let result
  let cameraEnabled = false
  let navigatorShare = false

  function cameraStart() {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function(stream) {
        let track = stream.getTracks()[0]
        cameraView.srcObject = stream

        cameraEnabled = true
      })
      .catch(function(error) {
        cameraEnabled = false
      })
  }
  function takePicture() {
    cameraSensor.width = cameraView.videoWidth
    cameraSensor.height = cameraView.videoHeight
    cameraSensor.getContext('2d').drawImage(cameraView, 0, 0)

    const imageData = cameraSensor.toDataURL('image/png')
    cameraOutput.src = imageData
    cameraOutput.classList.add('taken')

    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.src = imageData

    const canvas = document.createElement('canvas')
    canvas.width = 800
    canvas.height = 800

    image.onload = function() {
      let imageCoverSize = getCoverSize(
        image.naturalWidth,
        image.naturalHeight,
        canvas.width,
        canvas.height,
        0.5,
        0.5
      )
      const ctx = canvas.getContext('2d')
      ctx.translate(canvas.width, 0)
      ctx.scale(-1, 1)

      ctx.drawImage(
        image,
        imageCoverSize.offsetLeft,
        imageCoverSize.offsetTop,
        imageCoverSize.width,
        imageCoverSize.height
      )

      const imageFrame = new Image()
      imageFrame.onload = function() {
        ctx.translate(canvas.width, 0)
        ctx.scale(-1, 1)
        ctx.drawImage(imageFrame, 0, 0, canvas.width, canvas.height)

        const data = canvas.toDataURL('image/png')
        result = data
      }

      imageFrame.crossOrigin = 'Anonymous'
      imageFrame.src = frame
    }
  }

  function reset() {
    result = ''
    cameraOutput.src = ''
  }

  function changeFrame(e) {
    frame = e.detail.frame
  }

  function usePicture(evt) {
    var files = evt.target.files // FileList object
    var file = files[0]
    console.log(file.name)
    console.log('upload called')
    if (file.type.match('image.*')) {
      var reader = new FileReader()
      // Read in the image file as a data URL.
      reader.readAsDataURL(file)
      reader.onload = function(evt) {
        if (evt.target.readyState == FileReader.DONE) {
          const img = new Image()
          img.crossOrigin = 'Anonymous'
          img.src = evt.target.result
          const canvas = document.createElement('canvas')
          canvas.width = 800
          canvas.height = 800
          const cxt = canvas.getContext('2d')

          img.onload = () => {
            let imageCoverSize = getCoverSize(
              img.naturalWidth,
              img.naturalHeight,
              canvas.width,
              canvas.height,
              0.5,
              0.5
            )

            cxt.drawImage(
              img,
              imageCoverSize.offsetLeft,
              imageCoverSize.offsetTop,
              imageCoverSize.width,
              imageCoverSize.height
            )

            const imageFrame = new Image()
            imageFrame.onload = function() {
              // ctx.translate(canvas.width, 0)
              // ctx.scale(-1, 1)
              cxt.drawImage(imageFrame, 0, 0, canvas.width, canvas.height)

              const data = canvas.toDataURL('image/png')
              result = data
              cameraOutput.src = data
              src = data
            }
            imageFrame.crossOrigin = 'Anonymous'
            imageFrame.src = frame
          }
        }
      }
    } else {
      alert('not an image')
    }
  }

  async function getContent(entryId) {
    try {
      const entry = await client.getEntry(entryId)
      const { nombre, color, marcos, mensaje, nombreDelArchivo } = entry.fields
      mainColor = color
      frames = marcos.map(marco => `https:${marco.fields.file.url}`)
      frame = frames[0]
      fileName = nombreDelArchivo
      message = mensaje
      title = nombre
      isLoading = false
      cameraStart()
    } catch (e) {
      error = true
      console.log(e)
    }
  }

  onMount(() => {
    let f = new File([''], 'can-share')
    if (navigator.canShare && navigator.canShare({ files: [f] })) {
      navigatorShare = true
    }
    const path = window.location.pathname.replace('/', '')
    console.log(path)
    getContent(path)
  })
</script>

<style>
  main {
    padding: 1em;
    max-width: 500px;
    margin: 0 auto;
  }
  #camera--view,
  #camera--sensor,
  #camera--output {
    transform: scaleX(-1);
    filter: FlipH;
  }

  #camera--view,
  #camera--sensor,
  #camera--output {
    position: absolute;
    height: 320px;
    width: 320px;
    object-fit: cover;
  }
  img {
    text-indent: -10000px;
  }
  .frame {
    position: absolute;
    width: 320px;
  }
  .container {
    display: flex;
    justify-content: center;
    margin-bottom: 320px;
    /* position: relative; */
  }
  .result {
    display: flex;
    justify-content: center;
    align-content: center;
    /* margin-top: 380px; */
  }
  .title {
    text-align: center;
    /* padding-top: 2rem; */
  }
  .button {
    background-color: var(--theme-color);
    /* background-color: #f6dc00; */
    width: 100%;
    color: black;
    font-size: 15px;
    border-radius: 6px;
    border-style: none;
    border-width: 3px;
    font-weight: 800;
    padding: 12px 20px;
    cursor: pointer;
    margin: 1rem 0;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
  .button:hover {
    background: black;
    color: #fff;
  }
  .input-button {
    background-color: var(--theme-color);
    text-align: center;
    color: black;
    font-size: 15px;
    border-radius: 6px;
    border-style: none;
    border-width: 3px;
    font-weight: 800;
    padding: 12px 20px;
    cursor: pointer;
    margin: 1rem 0;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }

  .input-button:hover {
    background: black;
    color: #fff;
  }
  input[type='file'] {
    display: none;
  }
  .slider-container {
    padding-bottom: 2rem;
  }
  .actions-container {
    padding: 2rem 0;
  }
  .secondary {
    background-color: #333;
    color: #fff;
  }
  .show {
    display: block;
  }
  .notShow {
    display: none;
  }
</style>

<Loading {isLoading} {error}>
  <main>
    {#if !result}
      <h2 class="title">Escoja un marco</h2>
    {:else}
      <h2 class="title">¡Comparta su foto!</h2>
    {/if}

    <div class="slider-container">
      {#if !result}
        <Slider on:choose={changeFrame} {frames} selected={frame} {mainColor} />
      {/if}
    </div>

    <div class={result ? 'notShow' : 'container'}>
      <!-- Camera sensor -->
      <canvas id="camera--sensor" bind:this={cameraSensor} />
      <!-- Camera view -->
      <video id="camera--view" autoplay playsinline bind:this={cameraView}>
        <track kind="captions" />
      </video>

      <!-- Camera output -->
      <img
        {src}
        alt=""
        id="camera--output"
        bind:this={cameraOutput}
        crossorigin="Anonymous" />

      <img src={frame} alt="" class="frame" />
    </div>
    <div class="result">
      <img
        class={result ? 'show' : 'notShow'}
        src={result}
        width="320px"
        height="320px"
        alt=""
        crossorigin="Anonymous" />
    </div>
    <div class="actions-container">
      {#if cameraEnabled && !result}
        <button
          class="button"
          on:click={takePicture}
          style="--theme-color: {mainColor}">
          <CameraIcon size="16" />
          Tomar foto
        </button>
      {/if}
      {#if !result}
        <input type="file" name="file" id="file" on:change={usePicture} />
        <label
          class="input-button"
          for="file"
          style="--theme-color: {mainColor}">
          <UploadIcon size="16" />
          Subir foto
        </label>
      {/if}

      <div class="result">
        {#if result}
          <button class="button secondary" on:click={reset}>Otra</button>
        {/if}
      </div>

      <div class="result">
        {#if navigatorShare && result}
          <button
            class="button"
            on:click={() => shareResult(result, title, message)}
            style="--theme-color: {mainColor}">
            <Share2Icon size="16" />
            Compartir
          </button>
        {/if}
      </div>
      <div class="result">
        {#if result}
          <button
            class="button"
            on:click={() => downloadResult(result, fileName)}
            style="--theme-color: {mainColor}">
            <DownloadIcon size="16" />
            Descargar
          </button>
        {/if}
      </div>
    </div>
  </main>
</Loading>
